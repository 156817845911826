import payload_plugin_7i8wDZFFvK from "/app/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.24.4_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_zTyOlrlTwd from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.8.7_encoding@0.1.13_idb-keyval@6.2.1_ioredis_7tdtw4huhojmnax4jcsggbyeta/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_SoohkfJPbW from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.8.7_encoding@0.1.13_idb-keyval@6.2.1_ioredis_7tdtw4huhojmnax4jcsggbyeta/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_vsruMW6VAa from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.8.7_encoding@0.1.13_idb-keyval@6.2.1_ioredis_7tdtw4huhojmnax4jcsggbyeta/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_k9eoRITly8 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.8.7_encoding@0.1.13_idb-keyval@6.2.1_ioredis_7tdtw4huhojmnax4jcsggbyeta/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_pZSOh8dQ5p from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.8.7_encoding@0.1.13_idb-keyval@6.2.1_ioredis_7tdtw4huhojmnax4jcsggbyeta/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_CBkH7AObC0 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.8.7_encoding@0.1.13_idb-keyval@6.2.1_ioredis_7tdtw4huhojmnax4jcsggbyeta/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_PMxW9v0ySx from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.8.7_encoding@0.1.13_idb-keyval@6.2.1_ioredis_7tdtw4huhojmnax4jcsggbyeta/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_UhfjVVos0O from "/app/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.24.4_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_Dq4hNtoit5 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.8.7_encoding@0.1.13_idb-keyval@6.2.1_ioredis_7tdtw4huhojmnax4jcsggbyeta/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_mdUaYKFoCe from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.5_rollup@4.24.4_vue@3.5.12_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_92UgTyn0x2 from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.5_rollup@4.24.4_vue@3.5.12_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _1_airweb_lneS4MmBbC from "/app/plugins/1.airweb.ts";
import _2_sentry_client_Jgf1NT8rh8 from "/app/plugins/2.sentry.client.ts";
import _3_clarity_client_hgfsuVCxUk from "/app/plugins/3.clarity.client.ts";
export default [
  payload_plugin_7i8wDZFFvK,
  revive_payload_client_zTyOlrlTwd,
  unhead_SoohkfJPbW,
  router_vsruMW6VAa,
  payload_client_k9eoRITly8,
  navigation_repaint_client_pZSOh8dQ5p,
  check_outdated_build_client_CBkH7AObC0,
  chunk_reload_client_PMxW9v0ySx,
  plugin_vue3_UhfjVVos0O,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Dq4hNtoit5,
  switch_locale_path_ssr_mdUaYKFoCe,
  i18n_92UgTyn0x2,
  _1_airweb_lneS4MmBbC,
  _2_sentry_client_Jgf1NT8rh8,
  _3_clarity_client_hgfsuVCxUk
]